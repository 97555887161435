import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Link from "next/link";

import {
  Container,
  Typography,
  Select,
  MenuItem,
  Stack,
  Switch,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  getLatestRecommendedBooks,
  getLatestBooks,
  getBooksByCategory,
} from "../../redux/actions/books.action";

import { DOCS_PER_PAGE } from "../../services/constants";

const ContainerStyled = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius * 8,
  boxShadow: theme.customShadows.boxShadow1,
  marginBottom: theme.spacing(6),
  maxWidth: "100% !important",
  overflow: "auto",
  padding: `${theme.spacing(8)} ${theme.spacing(8)} ${theme.spacing(
    9
  )} ${theme.spacing(8)} !important`,
}));

const TitleContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(6),

  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(3),
  },
}));

const ViewAllLinkContainer = styled("a")(({ theme }) => ({
  alignItems: "center",
  color: theme.palette.primary.main,
  display: "flex",
  textDecoration: "underline",
  textUnderlineOffset: theme.spacing(2),
  transition: "all 0.3s",

  "&:hover": {
    textShadow: theme.customShadows.textShadow1,
    ...theme.animations.scaleText,
  },

  "&:hover svg": {
    transform: "translateX(3px)",
  },

  [theme.breakpoints.down("sm")]: {
    textDecoration: "none",
  },
}));

const sortingValueList = [
  { name: "Mới nhất", value: "-createdAt" },
  { name: "Cũ nhất", value: "createdAt" },
  { name: "Từ A đến Z", value: "slug" },
  { name: "Từ Z đến A", value: "-slug" },
  { name: "Đánh giá cao nhất", value: "-ratingAverage" },
];

const ContentBox = (props) => {
  const {
    title,
    haveViewAllLink,
    infoType,
    categorySlug,
    recommendedBooksFilter,
    haveSorting,
    sortingValue,
    setIsLoading,
    setSortingValue,
    setCurrentPage,
    setRecommendedBooksFilter,
    children,
  } = props;
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleChangeSorting = (event) => {
    setSortingValue(event.target.value);
    setIsLoading(true);
    setCurrentPage(1);
    const params = {
      sort: event.target.value,
      limit: DOCS_PER_PAGE,
      page: 1,
    };
    switch (infoType) {
      case "recommended-books":
        dispatch(getLatestRecommendedBooks(params));
        break;
      case "books":
        dispatch(getLatestBooks(params));
        break;
      case "category":
        dispatch(
          getBooksByCategory({
            ...params,
            categorySlug,
            isRecommended: recommendedBooksFilter,
          })
        );
    }
  };

  const handleChangeRecommendedBooksFilter = (event) => {
    setRecommendedBooksFilter(event.target.checked);
    setIsLoading(true);
    setCurrentPage(1);
    const params = {
      sort: sortingValue,
      limit: DOCS_PER_PAGE,
      page: 1,
      categorySlug,
      isRecommended: event.target.checked,
    };
    dispatch(getBooksByCategory(params));
  };

  useEffect(() => {
    if (categorySlug) {
      setSortingValue("-createdAt");
      setRecommendedBooksFilter(false);
    }
  }, [categorySlug, setSortingValue, setRecommendedBooksFilter]);

  return (
    <ContainerStyled sx={props.sx}>
      <TitleContainer>
        {title && (
          <Typography
            variant="h5"
            sx={{
              backgroundImage: theme.background.linearGradient,
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {title}
          </Typography>
        )}
        {haveViewAllLink && (
          <Link href={`/${infoType}`} passHref>
            <ViewAllLinkContainer>
              Xem tất cả
              <KeyboardArrowRightIcon />
            </ViewAllLinkContainer>
          </Link>
        )}
      </TitleContainer>
      {haveSorting && (
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          justifyContent={{ xs: "center", sm: "flex-end", md: "flex-end" }}
          alignItems={{ xs: "flex-end", sm: "center", md: "center" }}
          mb={6}
          spacing={{ xs: 0, sm: 2, md: 2 }}
        >
          {infoType === "category" && (
            <>
              <Typography
                variant="caption"
                htmlFor="switch-filter-recommended-books"
                component="label"
                sx={{ cursor: "pointer" }}
              >
                Chỉ hiện sách khuyên đọc:
              </Typography>
              <Switch
                id="switch-filter-recommended-books"
                checked={recommendedBooksFilter}
                onChange={handleChangeRecommendedBooksFilter}
              />
            </>
          )}
          <Typography variant="caption">Sắp xếp:</Typography>
          <Select
            variant="standard"
            value={sortingValue}
            onChange={handleChangeSorting}
            sx={{
              "& div, svg": { color: theme.palette.primary.main },
            }}
          >
            {sortingValueList.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                sx={{
                  color: theme.palette.primary.main,
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
      {children}
    </ContainerStyled>
  );
};

export default ContentBox;
