import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid, useMediaQuery } from "@mui/material";

import { wrapper } from "../redux/store";
import { getAllCategories } from "../redux/actions/categories.action";
import {
  getLatestRecommendedBooks,
  getLatestBooks,
} from "../redux/actions/books.action";

import ContentBox from "../components/Common/ContentBox.component";
import CustomCard from "../components/Common/CustomCard.component";

const HomePage = () => {
  const { latestRecommendedBooks, latestBooks } = useSelector(
    (state) => state.books
  );
  const { allCategories } = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    const getCategories = () => {
      dispatch(getAllCategories());
    };
    !matchesSM && getCategories();
  }, [matchesSM, dispatch]);

  return (
    <div>
      <ContentBox title="Sách mới" infoType="books" haveViewAllLink>
        <Grid container spacing={5}>
          {latestBooks?.books?.map((book) => (
            <Grid item xs={12} sm={6} md={3} key={book._id}>
              <CustomCard info={book} haveCategoryLink infoType="books" />
            </Grid>
          ))}
        </Grid>
      </ContentBox>

      <ContentBox
        title="Khuyên đọc"
        infoType="recommended-books"
        haveViewAllLink
      >
        <Grid container spacing={5}>
          {latestRecommendedBooks?.books?.map((book) => (
            <Grid item xs={12} sm={6} md={3} key={book._id}>
              <CustomCard info={book} haveCategoryLink infoType="books" />
            </Grid>
          ))}
        </Grid>
      </ContentBox>

      {!matchesSM && (
        <ContentBox title="Thể loại">
          <Grid container spacing={5}>
            {allCategories.map((category) => (
              <Grid item xs={12} sm={6} md={4} key={category._id}>
                <CustomCard info={category} infoType="categories" />
              </Grid>
            ))}
          </Grid>
        </ContentBox>
      )}
    </div>
  );
};

export const getStaticProps = wrapper.getStaticProps((store) => async () => {
  const params = {
    fields: "-borrowQuantity",
    limit: 4,
  };

  await store.dispatch(getLatestRecommendedBooks(params));
  await store.dispatch(getLatestBooks(params));

  return {
    props: {},
    revalidate: 1,
  };
});

export default HomePage;
