import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Link from "next/link";
import Image from "next/image";

import {
  Card,
  Typography,
  CardActions,
  Rating,
  Chip,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import { addBookToCart } from "../../redux/actions/cart.action";

import CustomButton from "./CustomButton.component";

const CardStyled = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 8,
  boxShadow: theme.customShadows.boxShadow2,
  position: "relative",

  "&:hover": {
    cursor: "pointer",
    boxShadow: theme.customShadows.boxShadow1,
    ...theme.animations.translateY,
  },
}));

const CardContentStyled = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  marginBottom: theme.spacing(6),
  overflow: "visible",
  padding: theme.spacing(4),
}));

const CardActionsStyled = styled(CardActions)(({ theme }) => ({
  paddingBottom: theme.spacing(6),

  "& button": {
    width: "100%",
  },
}));

const BookInfoContainer = styled("div")(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  gap: theme.spacing(1),
  flexWrap: "wrap",
  minHeight: theme.spacing(6),
  justifyContent: "space-between",
}));

const RatingContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  gap: theme.spacing(1),

  "& .MuiTypography-caption": {
    marginLeft: theme.spacing(2),
  },
}));

const CategoryLinkContainer = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  marginBottom: theme.spacing(2),

  "&:hover": {
    textShadow: theme.customShadows.textShadow1,
    ...theme.animations.scaleText,
  },
}));

const ChipStyled = styled(Chip)(({ theme }) => ({
  boxShadow: theme.customShadows.boxShadow1,
  color: "inherit",
  cursor: "pointer",
  position: "absolute",
  top: "20px",
  right: "10px",
}));

const CustomCard = ({ info, haveCategoryLink, infoType }) => {
  const [successSound, setSuccessSound] = useState(null);
  const [failSound, setFailSound] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setSuccessSound(new Audio("/static/sounds/successSound.mp3"));
    setFailSound(new Audio("/static/sounds/failSound.mp3"));
  }, []);

  const playSound = (type) => {
    type === "success" ? successSound.play() : failSound.play();
  };

  const handleClickAddToCart = (event, book) => {
    event.preventDefault();
    dispatch(addBookToCart(book, playSound));
  };

  const hrefCardLink = `/${infoType}/${info.slug}`;

  return (
    <Link href={`${hrefCardLink}`} passHref>
      <a>
        <CardStyled>
          <div>
            <Image
              layout={infoType === "books" ? "responsive" : "intrinsic"}
              height="264"
              width="374"
              sizes="50vw"
              src={
                infoType === "books"
                  ? `${info.imageCover}` ||
                    "/static/images/defaultBookCover.svg"
                  : `/static/images/categories/${info.imageCover}`
              }
              alt={info.slug}
              objectFit={infoType === "books" ? "cover" : "contain"}
            />
          </div>
          <CardContentStyled
            sx={{
              height: infoType === "books" ? "110px" : "60px",
            }}
          >
            <Typography
              variant="subtitle1"
              align={infoType === "books" ? "left" : "center"}
              fontWeight="bold"
              fontSize="1.2rem"
              lineHeight="1.2"
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical", // only display 2 rows
              }}
            >
              {info.title}
            </Typography>
            {infoType === "books" ? (
              <Stack spacing={2} mt={2}>
                <BookInfoContainer>
                  <RatingContainer>
                    {info?.ratingAverage ? (
                      <Typography
                        variant="h6"
                        color="#faaf00"
                        fontWeight="bold"
                      >
                        {info?.ratingAverage?.toFixed(1)}
                      </Typography>
                    ) : null}
                    <Rating
                      value={info?.ratingAverage || 0}
                      precision={0.5}
                      size="small"
                      readOnly
                    />
                    <Typography variant="subtitle2">
                      {`(${
                        info?.ratingQuantity ? info?.ratingQuantity : 0
                      } đánh giá)`}
                    </Typography>
                  </RatingContainer>
                  {info.isRecommended && (
                    <ChipStyled
                      label="Khuyên đọc"
                      color="secondary"
                      size="medium"
                      icon={<ThumbUpAltOutlinedIcon />}
                    />
                  )}
                </BookInfoContainer>
                {haveCategoryLink ? (
                  <CategoryLinkContainer
                    variant="subtitle2"
                    noWrap
                    sx={{ marginTop: "0 !important" }}
                  >
                    <Link href={`/categories/${info.category.slug}`} passHref>
                      {info.category.title}
                    </Link>
                  </CategoryLinkContainer>
                ) : null}
              </Stack>
            ) : null}
          </CardContentStyled>
          {infoType === "books" ? (
            <CardActionsStyled>
              <CustomButton
                variant="contained"
                size="large"
                onClick={(event) => handleClickAddToCart(event, info)}
                disabled={info?.owners[0]?.quantity < 1}
              >
                {info?.owners[0]?.quantity < 1 ? null : (
                  <AddShoppingCartIcon sx={{ marginRight: "4px" }} />
                )}{" "}
                <Typography variant="body2">
                  {info?.owners[0]?.quantity < 1
                    ? "Đã cho mượn"
                    : "Cho vào giỏ"}
                </Typography>
              </CustomButton>
            </CardActionsStyled>
          ) : null}
        </CardStyled>
      </a>
    </Link>
  );
};

export default CustomCard;
