import {
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_FAIL,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
} from "../constants/categories.constant";

import { CategoryService } from "../../services/allApiServices";

const Category = new CategoryService();

const getAllCategories = () => async (dispatch) => {
  const { data } = await Category.getAll({ sort: "-title" });

  if (data.status === "success") {
    dispatch({
      type: GET_ALL_CATEGORIES_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: GET_ALL_CATEGORIES_FAIL,
      payload: data,
    });
  }
};

const getCategory = (id) => async (dispatch) => {
  const { data } = await Category.getOne(id);

  if (data.status === "success") {
    dispatch({
      type: GET_CATEGORY_SUCCESS,
      payload: data,
    });
  } else {
    dispatch({
      type: GET_CATEGORY_FAIL,
      payload: data,
    });
  }
};

export { getAllCategories, getCategory };
